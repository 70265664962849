<template>
    <div class="onboard-img-container">
        <div class="img-wrapper">
            <img :src="require('@/assets/images/onboard/onboard-1.png')" alt="" />
        </div>
        <div class="content">
            <div class="title">국내 최초 모바일 결혼정보회사</div>
            <div class="desc">
                <p>결혼정보회사 퀄리티의 서비스를</p>
                <p>모바일앱으로 간편하고, 합리적인 가격에 제공합니다.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OnBoard1',
}
</script>

<style scoped></style>
